import styled, { createGlobalStyle } from "styled-components"
import { Transition } from "@headlessui/react"
import { globalHistory } from "@reach/router"
import Logo from "../images/DXies-News_logo.png"
import Search from "./search"
import React, { useEffect, useState } from "react"
import { MdSearch } from "react-icons/md"
import { useScrollLock } from "../hooks/use_scroll_lock"
import { atom, useAtom } from "jotai"

export const searchShownAtom = atom(false)

const Global = createGlobalStyle`
  body,html{
    margin : 0;
    padding : 0;
  }
`
const MenuIcon = styled.button`
  position: fixed;
  right: 5.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 5;
  div {
    width: 1.3rem;
    height: 0.13rem;
    background: white;
    border-radius: 5px;
    transform-orign: 50%;
    position: relative;
    :first-child {
      transform-origin: 5% 50%;
      transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
      background: ${({ nav }) => (nav ? "black" : "white")};
    }
    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }
    :nth-child(3) {
      transform-origin: 0% 50%;
      transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
      background: ${({ nav }) => (nav ? "black" : "white")};
    }
  }
`
const MenuLinks = styled.nav`
  display: ${({ nav }) => (nav ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 20%;
  border: 1px solid #f2f2f2;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 300ms;
  transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")};
  table {
    margin-top: 80px;
  }
  tr {
    margin-top: 0rem;
    padding: 10px 50px;
    display: block;
    border-bottom: 1px solid #f2f2f2;
    border-collapse: collapse;
  }
  tr:first-child {
    border-top: 1px solid #f2f2f2;
  }
  a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
    transition: color 300ms;
    :hover {
      color: #6ab4ff;
    }
  }
  @media (max-width: 42rem) {
    width: 60%;
  }
`
export default function Header() {
  const [nav, showNav] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const { lockScroll, unlockScroll } = useScrollLock()
  const [isSearchShown, setIsSearchShown] = useAtom(searchShownAtom)
  const handleClickSearch = event => {
    setIsSearchShown(current => !current)
  }

  // Fix: Unscrollable after page changes
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setOpen(false)
        unlockScroll()
      }
    })
  }, [])

  const handleClose = () => {
    setOpen(false)
    unlockScroll()
  }

  return (
    <>
      <Transition show={isOpen}>
        {/* Background overlay */}
        <Transition.Child
          className="fixed inset-0 z-30 w-screen bg-black bg-opacity-30"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          onClick={handleClose}
        ></Transition.Child>
        <Transition.Child
          style={{ zIndex: 50 }}
          className="fixed top-0 left-0 h-screen w-fit overflow-y-auto shadow-2xl scrollbar-hide"
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        ></Transition.Child>
      </Transition>
      <div className="custom-header">
        <div className="shadow-xl">
          <div className="container flex items-center h-[87px] gap-4 py-3 md:py-3">
            {/* Nav Start */}
            <Global />

            <MenuIcon
              className="menu-icon"
              nav={nav}
              onClick={() => showNav(!nav)}
            >
              <div />
              <div />
              <div />
            </MenuIcon>
            <MenuLinks nav={nav}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <a href="/">Home</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="/terms-of-use/">ご利用にあたり</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="/privacy-policy/">個人情報について</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </MenuLinks>
            <div className="logo-img">
              <a href="/">
                <img src={Logo} alt="Logo" />
              </a>
            </div>

            <div className="fixed right-20 md:right-32">
              <MdSearch
                className="cursor-pointer focus-within:text-red-500 text-white"
                size={35}
                onClick={handleClickSearch}
              />
              {isSearchShown && <Search />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

