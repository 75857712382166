import React from "react"
export const useScrollLock = () => {
  const lockScroll = React.useCallback(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth
    document.body.style.overflow = "hidden"
    // To Prevent layout shift
    console.log(scrollBarCompensation)
    document.body.style.paddingRight = `${scrollBarCompensation}px`
  }, [])

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = ""
    // To Prevent layout shift
    document.body.style.paddingRight = "0px"
  }, [])

  return {
    lockScroll,
    unlockScroll,
  }
}

